import React from "react"
import Layout from "@components/layout/Layout/Layout"
import Collection from "@components/partials/NFTsPage"

const MyNFTsPage = () => (
    <Layout
        title="Mark McKenna's Heroes & Villains NFT"
        contentClassName="MyNFTsPageContent px-0"
        type="mynft"
    >
        <Collection type="mynfts"/>
    </Layout>
)

export default MyNFTsPage
